import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { HttpLink } from '@apollo/client'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )

      if (message === 'jwt expired') {
        localStorage.setItem('logout', Date.now())
      }
    })

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('letsparty-user-token')
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : null,
    },
  }
})

const httpLink = new HttpLink({
  uri: '/graphql',
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  errorLink,
  authLink,
  httpLink,
}
