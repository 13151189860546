import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
} from '@apollo/client'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import links from './utils/apolloLinks'
import ScrollToTop from './components/ScrollToTop'
document.title = "Let's Party Torino"

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([links.errorLink, links.authLink, links.httpLink]),
})

const App = lazy(() => import('./App'))
const Admin = lazy(() => import('./components/admin/admin'))

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <Switch>
          <Route path='/admin*' render={props => <Admin />} />

          <Route path='/' render={() => <App />} />
        </Switch>
      </Suspense>
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
)
